<script>
import BaseModal from 'lowfoot-components/BaseModal'
import CameraFrame from '@/components/camera/CameraFrame'
import DocumentService from '@/services/DocumentService'
import EnrollmentService from '@/services/EnrollmentService'
import camelCase from 'lodash/camelCase'
import { resetObject } from 'lowfoot-helpers/util'

export default {
	name: 'Camera',
	components: { CameraFrame, BaseModal },
	props: ['contentDocumentScan'],
	data: () => ({
		loading: false,
		submissionError: false,
		cameraError: {
			title: '',
			message: '',
			code: ''
		}
	}),
	created: async function () {
		if (!this.contentDocumentScan) {
			console.error('Trying to access the camera without a document')
			await this.$router.push({ name: 'enrollment' })
		}
	},
	computed: {
		scrollPosition: function () {
			return `#content-document-scan-${this.contentDocumentScan.id}`
		},
		cameraFrameComponentRef: function () {
			const cameraFrameRef = this.$refs.cameraFrame
			const cameraFrameComponentRef = cameraFrameRef ? cameraFrameRef.$refs.cameraFrameComponent : null
			return cameraFrameComponentRef ? cameraFrameComponentRef.$refs.fileUpload : null
		}
	},
	methods: {
		handleImage: async function (image) {
			if (image) {
				this.loading = true
				this.hideError()
				const imageBlob = await this.prepareImage(image)
				try {
					this.$store.commit('cms/setDocumentsCreated', false)
					this.$store.commit('cms/setDocumentScanned', this.contentDocumentScan.id)
					const enrollmentService = await EnrollmentService.fetchOrCreate()
					const documentService = new DocumentService(enrollmentService, this.contentDocumentScan)
					await documentService.scanDocument(imageBlob)
					enrollmentService.validate()
					await this.$router.push({ name: 'enrollment', hash: this.scrollPosition })
				} catch (e) {
					let errorResponse = e.response && e.response.data && e.response.data.code !== 500
						? e.response.data
						: { error: 'submissionError', code: 'E-S0' }
					errorResponse.message = errorResponse.message || errorResponse.error || 'unexpectedError'
					if (errorResponse.message === 'Access denied' || errorResponse.code === 403) {
						await this.$router.push({ name: 'error', params: { sessionTimeOut: true } })
					} else {
						const error = [camelCase(errorResponse.message), `[${errorResponse.code}]`]
						this.submissionError = true
						this.handleError(error)
						throw new Error('Camera error')
					}
				} finally {
					this.loading = false
				}
			} else {
				await this.returnToEnrollment()
			}
		},
		prepareImage: async function (image) {
			const imageResponse = await fetch(image)
			return imageResponse.blob()
		},
		chooseImageToUpload: function () {
			if (this.cameraFrameComponentRef) this.cameraFrameComponentRef.click()
		},
		returnToEnrollment: async function () {
			await this.$router.push({ name: 'enrollment', hash: this.scrollPosition })
		},
		handleError: function (error) {
			this.loading = false
			this.cameraError = this.setCameraError(error)
			this.$modal.show('cameraErrorModal')
		},
		hideError: function () {
			this.cameraError = resetObject(this.cameraError, '')
		},
		setCameraError: function (error) {
			if (!Array.isArray(error)) return { title: `${this.$t('photo.submissionErrorTitle')}`, message: '' }
			const errorMessageKey = error[0]
			const errorCode = error[1]
			const errorTitleTranslated = /S/.test(errorCode) // separate submission error titles from generic camera error
				? this.$t('photo.submissionErrorTitle')
				: this.$t('photo.cameraErrorTitle')
			return {
				title: errorTitleTranslated,
				message: this.$t('photo.error.' + errorMessageKey),
				code: errorCode
			}
		}
	}
}
</script>
<template>
	<div class="camera">
		<base-loading :loading="loading" modifiers="dark-overlay large-spinner">
			<camera-frame v-if="contentDocumentScan"
				ref="cameraFrame"
				:show-upload-button="true"
				:submission-error="submissionError"
				@captured-image="handleImage"
				@camera-error="handleError"
				@hide-error="hideError" />
			<base-modal v-show="!loading"
				class="camera__modal"
				modal-name="cameraErrorModal"
				:adaptive="true"
				:height="'auto'"
				:clickToClose="false"
				:scrollable="true"
				:width="'95%'"
				:max-width="510"
				modifiers="flex-column padding-1rem">
				<h3 class="camera__modal-title">{{ cameraError.title }}</h3>
				<div class="camera__upload-box">
					<p class="camera__upload-message" v-html="$t('photo.uploadHelpMessage')" />
					<base-button modifiers="accent medium centered" @click="chooseImageToUpload">
						<font-awesome-icon class="camera__upload-icon" :icon="['fas', 'file-upload']" />
						{{ $t('photo.upload')}}
					</base-button>
				</div>
				<div class="camera__error-box">
					<p class="camera__error-subtitle">{{ $t('photo.additionalInformation') }}</p>
					<p class="camera__error-message" v-html="cameraError.message" />
					<p class="camera__error-code">{{ cameraError.code }}</p>
					<base-button modifiers="accent-inverse medium centered" @click="returnToEnrollment">
						{{ $t('photo.returnToEnrollment') }}
					</base-button>
				</div>
			</base-modal>
		</base-loading>
	</div>
</template>
<style lang="scss" scoped>
.camera {
	height: 100%;
	width: 100%;
	overflow: hidden;
	touch-action: none;
	background: #000;
	position: absolute;
	top: 0;
	&__modal {
		@media only screen and (orientation: landscape) {
			font-size: 90%;
		}
		@media only screen and (min-width: 600px) {
			padding: 2rem;
		}
		&-title {
			font-size: 0.95rem;
			font-weight: 600;
			letter-spacing: var(--letter-spacing-expanded);
			margin: 1rem 0;
			text-align: left;
			width: 100%;
		}
	}
	&__upload {
		&-box {
			border: 1px solid var(--accent-colour);
			border-radius: var(--border-radius);
			margin: 0.5rem auto;
			padding: 1rem;
			width: 100%;
		}
		&-message {
			font-size: 0.85rem;
			letter-spacing: var(--letter-spacing-expanded);
			margin-bottom: 1rem;
		}
		&-icon {
			height: 25px;
			margin-right: 1rem;
		}
	}
	&__error {
		&-box {
			border: 1px solid white;
			padding: 1rem;
		}
		&-subtitle {
			font-size: 0.9rem;
			font-weight: 600;
			letter-spacing: var(--letter-spacing-expanded);
			margin: 0.5rem 0;
		}
		&-message {
			font-size: 0.8rem;
			font-weight: 400;
			padding: 0.5rem;
			margin: 0.5rem 0;
		}
		&-code {
			color: #DDD;
			font-size: 0.7rem;
			margin: 0.5rem 0 1rem 0;
		}
	}
}
</style>
