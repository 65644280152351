import rg4js from 'raygun4js'

export default {
	data: function () {
		return {
			video: null,
			canvas: null,
			mediaStreamTrack: null,
			maskOverlay: null,
			shutterClicked: false,
			cameraError: false,
			cameraConfig: {
				facingMode: 'environment',
				width: { min: 1280, ideal: 1720, max: 1920 },
				height: { min: 720, ideal: 1080, max: 1080 }
			},
			cameraFallbackConfig: {
				facingMode: 'environment',
				height: { min: 720, ideal: null, max: 1920 },
				width: { min: 720, ideal: null, max: 1920 }
			},
			cameraFallbackAttempt: true
		}
	},
	mounted: function () {
		this.video = this.$refs.video
		this.canvas = this.$refs.canvas
		this.maskOverlay = this.$refs.maskOverlay
	},
	activated: function () {
		this._stopVideoTrack()
		if (!this.loading) this._startVideoTrack()
	},
	deactivated: function () {
		this._resetError()
		this._stopVideoTrack()
	},
	methods: {
		takePhoto: function () {
			if (this.mediaSupported && !this.shutterClicked) {
				this.shutterClicked = true
				this._matchSizes()
				const image = this._canvasToImage()
				this._stopVideoTrack()
				this.$emit('captured-image', image)
			}
		},
		uploadPhoto: function () {
			let fileUpload
			if (this.mediaSupported) {
				this._stopVideoTrack()
			}
			fileUpload = this.$refs.fileUpload
			if (fileUpload) {
				let file = fileUpload.files[0]
				if (file) {
					const image = URL.createObjectURL(file)
					if (this.$refs.fileUpload) this.$refs.fileUpload.value = null
					if (this.$refs.chooseImage) this.$refs.chooseImage.value = null
					if (this.$refs.uploadButton) this.$refs.uploadButton.reset()
					if (this.$refs.uploadIcon) this.$refs.uploadIcon.reset()
					this.$emit('captured-image', image)
				}
			}
		},
		cancel: function () {
			this._stopVideoTrack()
			this.$emit('captured-image', null)
		},
		_startVideoTrack: function () {
			this._resetError()
			if (this.mediaSupported && this.video && !this.video.srcObject) {
				const params = {
					audio: false,
					video: this.cameraConfig
				}
				this._loadCamera(params)
			} else {
				this._handleError('MediaUnsupportedError')
			}
		},
		_stopVideoTrack: function () {
			if (this.video && this.video.srcObject) {
				this.video.srcObject.getTracks().forEach(track => track.stop())
				this.video.srcObject = null
				this.mediaStreamTrack = null
			}
		},
		_matchSizes: function () {
			this.canvas.width = this.video.videoWidth
			this.canvas.height = this.video.videoHeight
		},
		_matchMaskSize: function () {
			this.maskOverlay.$el.style.width = this.video.clientWidth + 'px'
		},
		_canvasToImage: function () {
			const context = this.canvas.getContext('2d')
			context.drawImage(this.video, 0, 0)
			return this.canvas.toDataURL()
		},
		_loadCamera: function (params) {
			navigator.mediaDevices.getUserMedia(params).then((stream) => {
				this.video.srcObject = stream
				this.cameraFallbackAttempt = true
				let videoPlayback = this.video.play()
				if (videoPlayback !== undefined) {
					videoPlayback.then(() => {
						this._matchMaskSize()
					}).catch((error) => {
						this._handleError(error.name)
					}).finally(() => {
						if (!this.$refs.video) {
							this._stopVideoTrack()
						} else if (this.video.srcObject) {
							this.mediaStreamTrack = this.video.srcObject.getVideoTracks()[0]
							if (this.mediaStreamTrack) {
								this.mediaStreamTrack.addEventListener('mute', () => {
									this._handleError('VideoPausedError')
								})
							}
						}
					})
				} else {
					this._handleError('VideoPlaybackError')
				}
			}).catch((error) => {
				if (this.cameraFallbackAttempt) {
					this.cameraFallbackAttempt = false
					const params = {
						audio: false,
						video: this.cameraFallbackConfig
					}
					this._loadCamera(params)
				} else {
					this._handleError(error.name)
				}
			})
		},
		_handleError: function (error) {
			let errorMessageKey
			let errorCode = '[E0]'
			this.cameraError = true
			this._stopVideoTrack()
			if (error === 'NotFoundError' || error === 'DevicesNotFoundError') {
				// required track is missing
				errorMessageKey = 'technicalIssues'
				errorCode = '[E1]'
			} else if (error === 'NotReadableError' || error === 'TrackStartError') {
				// camera already in use
				errorMessageKey = 'notReadable'
			} else if (error === 'OverconstrainedError' || error === 'ConstraintNotSatisfiedError') {
				// constraints can not be satisfied by device
				errorMessageKey = (this.$options.name === 'PortraitFrame') ? 'overconstrainedPortrait' : 'overconstrained'
			} else if (error === 'NotAllowedError' || error === 'PermissionDeniedError') {
				// permission denied in browser
				errorMessageKey = 'notAllowed'
			} else if (error === 'TypeError' || error === 'TypeError') {
				// empty constraints object or insecure context
				errorMessageKey = 'technicalIssues'
				errorCode = '[E2]'
			} else if (error === 'SecurityError') {
				// user media support is disabled
				errorMessageKey = 'technicalIssues'
				errorCode = '[E3]'
			} else if (error === 'MediaUnsupportedError') {
				// user media is not supported
				errorMessageKey = 'technicalIssues'
				errorCode = '[E4]'
			} else if (error === 'VideoPlaybackError') {
				// video playback object was not defined
				errorMessageKey = 'technicalIssues'
				errorCode = '[E5]'
			} else if (error === 'VideoPausedError') {
				// camera/video playback paused by user
				errorMessageKey = 'paused'
			} else {
				// other errors
				errorMessageKey = (this.$options.name === 'PortraitFrame') ? 'portrait' : 'technicalIssues'
			}
			if (errorMessageKey !== 'notAllowed') {
				rg4js('send', {
					error: `Camera error. ${error}`,
					customData: [{ errorCode }],
					tags: ['camera-frame-mixin']
				})
			}
			this.$emit('camera-error', [ errorMessageKey, errorCode ])
		},
		_resetError: function () {
			this.cameraError = false
			this.$emit('hide-error', true)
		},
		videoPaused: function () {
			this._handleError('VideoPausedError')
		}
	},
	computed: {
		mediaSupported: function () {
			return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
		},
		enableShutterButton: function () {
			return !!(this.mediaSupported && this.video && this.mediaStreamTrack && !this.cameraError)
		}
	},
	beforeDestroy () {
		this._stopVideoTrack()
	}
}
