<script>
import mask from '@/assets/images/camera_mask.png'
export default {
	name: 'TemplateMask',
	data: () => ({
		cameraMask: mask
	})
}
</script>

<template>
	<div class="template-mask">
		<div class="template-mask__top template-mask__top--left"></div>
		<div class="template-mask__top"></div>
		<div class="template-mask__top template-mask__top--right"></div>
		<div class="template-mask__center--left"></div>
		<div class="template-mask__center">
			<img :src="cameraMask"/>
		</div>
		<div class="template-mask__center--right"></div>
		<div class="template-mask__bottom template-mask__bottom--left"></div>
		<div class="template-mask__bottom">
			<div class="template-mask__message" v-html="$t('camera.frameInstruction')"></div>
		</div>
		<div class="template-mask__bottom template-mask__bottom--right"></div>
	</div>
</template>

<style lang="scss">
	$overlay-color: rgba(24, 24, 24, 0.58);
	.template-mask {
		position: absolute;
		width: 100%;
		height: 100%;
		display: grid;
		grid-template-columns: 1fr 85% 1fr;
		grid-template-rows: 1fr auto 1fr;
		@media (orientation: landscape) and (max-height: 300px) {
			grid-template-columns: 1fr 70% 1fr;
			grid-template-rows: 10% auto 1fr;
		}
		@media (orientation: landscape) and (max-height: 240px) {
			grid-template-columns: 1fr 65% 1fr;
			grid-template-rows: 0.25fr auto 1fr;
		}
		&__top {
			grid-row: 1 / span 1;
			grid-column: 2 / span 1;
			background-color: $overlay-color;
			&--left {
				grid-column: 1 / span 1;
			}
			&--right {
				grid-column: 3 / span 1;
			}
		}
		&__bottom {
			grid-row: 3 / span 1;
			grid-column: 2 / span 1;
			background-color: $overlay-color;
			&--left {
				grid-column: 1 / span 1;
			}
			&--right {
				grid-column: 3 / span 1;
			}
		}
		&__center {
			grid-row: 2 / span 1;
			grid-column: 2 / span 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			img {
				width: 100%;
			}
			&--left {
				grid-row: 2 / span 1;
				grid-column: 1 / span 1;
				background-color: $overlay-color;
			}
			&--right {
				grid-row: 2 / span 1;
				grid-column: 3 / span 1;
				background-color: $overlay-color;
			}
		}
		&__message {
			width: 100%;
			text-align: center;
			color: white;
			font-size: 1rem;
			font-family: 'Noto Sans JP', 'Roboto', sans-serif;
			font-weight: bold;
			@media only screen and (max-width: 768px) {
				font-size: 0.7rem;
			}
			@media (orientation: landscape) and (max-height: 300px) {
				font-size: 0.5rem;
			}
		}
	}
</style>
