<script>
import PortraitFrame from '@/components/camera/PortraitFrame.vue'
import LandscapeFrame from '@/components/camera/LandscapeFrame.vue'

export default {
	name: 'CameraFrame',
	components: {
		PortraitFrame,
		LandscapeFrame
	},
	props: {
		showUploadButton: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		},
		submissionError: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		viewer: null
	}),
	mounted: function () {
		const match = window.matchMedia('(orientation: portrait)')
		this.orientationChange(match)
	},
	methods: {
		orientationChange: function (match) {
			this.viewer = match.matches ? 'PortraitFrame' : 'LandscapeFrame'
			let _this = this
			match.addListener(function (m) {
				_this.viewer = m.matches ? 'PortraitFrame' : 'LandscapeFrame'
			})
		},
		imageCaptured: function (image) {
			this.$emit('captured-image', image)
		},
		displayCameraError: function (message) {
			this.$emit('camera-error', message)
		},
		hideCameraError: function () {
			this.$emit('hide-error', true)
		}
	}
}
</script>
<template>
	<keep-alive>
		<component :is="viewer"
			ref="cameraFrameComponent"
			:loading="loading"
			:submission-error="submissionError"
			:show-upload-button="showUploadButton"
			@captured-image="imageCaptured"
			@camera-error="displayCameraError"
			@hide-error="hideCameraError" />
	</keep-alive>
</template>
