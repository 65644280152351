export default {
	data: () => ({
		fullscreenElements: []
	}),
	mounted: function () {
		this._refresh()
		window.addEventListener('resize', this._refresh)
	},
	activated: function () {
		this._refresh()
	},
	beforeUpdate: function () {
		this._refresh()
	},
	beforeDestroy: function () {
		window.removeEventListener('resize', this._refresh)
	},
	methods: {
		registerFullscreenElement: function (className) {
			this.fullscreenElements.push(className)
		},
		_resize: function () {
			// make sure we're at the top of the window to measure the height
			window.scrollTo(0, 0)
			const height = window.innerHeight
			this.fullscreenElements.forEach(function (elementName) {
				document.getElementsByClassName(elementName).forEach(function (element) {
					element.style.height = height + 'px'
				})
			})
			// make sure we've scrolled to the top after resizing
			window.scrollTo(0, 0)
		},
		_refresh: function () {
			this._resize()
			// call a timeout to recalculate heights incase UI elements have changed (like url bar)
			const _this = this
			setTimeout(function () {
				_this._resize()
			}, 300)
		}
	}
}
