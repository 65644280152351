<script>
import TemplateMask from '@/components/camera/TemplateMask'
/* TODO: extract to share code */
import cameraFrameMixin from '@/mixins/camera-frame-mixin'
import viewportCalculationMixin from '@/mixins/viewport-calculation-mixin'
/* TODO: extract to share code */

export default {
	name: 'PortraitFrame',
	data: function () {
		return {
			cameraConfig: {
				facingMode: 'environment',
				height: { min: 1080, ideal: null, max: 1920 },
				width: { min: 1080, ideal: null, max: 1920 }
			}
		}
	},
	components: {
		TemplateMask
	},
	props: {
		loading: {
			type: Boolean,
			default: false
		},
		showUploadButton: {
			type: Boolean,
			default: false
		},
		submissionError: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		showCameraButtons: function () {
			return !(this.cameraError || this.submissionError)
		},
		showTemplateMask: function () {
			return this.mediaSupported && !(this.loading || this.cameraError || this.submissionError)
		}
	},
	mixins: [cameraFrameMixin, viewportCalculationMixin],
	mounted: function () {
		this.registerFullscreenElement('portrait-frame')
		const isIOSOldVersion = this.$store.getters['cms/getDevice'].isIOSOldVersion
		if (isIOSOldVersion) {
			this.cameraConfig = {
				facingMode: 'environment',
				width: { min: 790, ideal: 0, max: 790 },
				height: { min: 700, ideal: 0, max: 700 }
			}
		}
	},
	methods: {
		chooseFiles: function () {
			this.$refs.fileUpload.click()
		},
		takePhoto: function () {
			if (this.mediaSupported) {
				this._matchSizes()
				const image = this._canvasToImage()
				this._stopVideoTrack()
				this.$emit('captured-image', image)
			}
		}
	}
}
</script>
<template>
	<div class="portrait-frame">
		<div class="portrait-frame__top">
			<base-button modifiers="accent circle small-circle"
						class="portrait-frame__button portrait-frame__button--close"
						v-show="showCameraButtons"
						@click="cancel">
				<font-awesome-icon :icon="['fas', 'times']" />
			</base-button>
		</div>
		<div class="portrait-frame__middle">
			<template-mask ref="maskOverlay" v-show="showTemplateMask" />
			<video ref="video" id="videoElement" autoplay playsinline muted v-show="!loading" @pause="videoPaused"></video>
		</div>
		<div class="portrait-frame__bottom">
			<input ref="fileUpload" id="fileUpload" type="file" accept="image/*" @change="uploadPhoto" hidden />
			<base-button modifiers="accent circle small-circle"
						class="portrait-frame__button portrait-frame__button--upload"
						v-show="showUploadButton && showCameraButtons"
						@click="chooseFiles">
				<font-awesome-icon :icon="['fas', 'file-upload']" />
			</base-button>
			<base-button modifiers="accent circle big-circle"
						class="portrait-frame__button"
						v-show="enableShutterButton"
						@click="takePhoto">
				<font-awesome-icon :icon="['fas', 'camera']" />
			</base-button>
		</div>
		<canvas ref="canvas"></canvas>
	</div>
</template>
<style lang="scss" scoped>
.portrait-frame {
	display: flex;
	flex-direction: column;
	height: 100vh;
	width: 100%;
	background-color: black;
	pointer-events: none;
	&__top {
		display: flex;
		justify-content: flex-start;
		height: 20%;
	}
	&__middle {
		display: flex;
		height: 60%;
		position: relative;
		align-items: center;
		justify-content: center;
		video {
			height: 100%;
			max-width: 100%;
			max-height: 100%;
		}
	}
	&__bottom {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 20%;
		padding: 1rem;
	}
	&__button {
		font-size: 3rem;
		width: 6rem;
		height: 6rem;
		padding: 1rem;
		pointer-events: visible;
		@media (max-width: 700px) {
			width: auto;
			height: auto;
			font-size: 2.5rem;
		}
		&--close {
			height: 3.5rem;
			width: 3.5rem;
			min-height: 0;
			padding: 0;
			font-size: 2.5rem;
			margin: 2rem 2rem;
			@media (max-width: 700px) {
				height: 2.5rem;
				width: 2.5rem;
				font-size: 1.8rem;
				margin: 1rem 1rem;
			}
		}
		&--upload {
			height: 4rem;
			width: 4rem;
			min-height: 0;
			font-size: 2rem;
			padding: 0;
			margin: 0;
			position: absolute;
			left: 2rem;
			@media (max-width: 700px) {
				height: 2.5rem;
				width: 2.5rem;
				font-size: 1.5rem;
				left: 1rem;
			}
		}
	}
	canvas {
		display: none;
	}
}
</style>
