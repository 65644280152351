<script>
import TemplateMask from '@/components/camera/TemplateMask'
/* TODO: extract to share code */
import cameraFrameMixin from '@/mixins/camera-frame-mixin'
import viewportCalculationMixin from '@/mixins/viewport-calculation-mixin'
/* TODO: extract to share code */

export default {
	name: 'LandscapeFrame',
	components: {
		TemplateMask
	},
	props: {
		loading: {
			type: Boolean,
			default: false
		},
		maskUrl: {
			type: String,
			default: ''
		},
		showUploadButton: {
			type: Boolean,
			default: false
		},
		documentName: {
			type: String,
			default: ''
		},
		submissionError: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		showCameraButtons: function () {
			return !(this.cameraError || this.submissionError)
		},
		showTemplateMask: function () {
			return this.mediaSupported && !(this.loading || this.cameraError || this.submissionError)
		}
	},
	mixins: [cameraFrameMixin, viewportCalculationMixin],
	mounted: function () {
		this.registerFullscreenElement('landscape-frame')
	},
	methods: {
		chooseFiles: function () {
			this.$refs.fileUpload.click()
		},
		takePhoto: function () {
			if (this.mediaSupported) {
				this._matchSizes()
				const image = this._canvasToImage()
				this._stopVideoTrack()
				this.$emit('captured-image', image)
			}
		}
	}
}
</script>

<template>
	<div class="landscape-frame">
		<div class="landscape-frame__left">
			<base-button modifiers="accent circle small-circle"
						class="landscape-frame__button landscape-frame__button--close"
						v-show="showCameraButtons"
						@click="cancel">
				<font-awesome-icon :icon="['fas', 'times']" />
			</base-button>
		</div>
		<div class="landscape-frame__middle">
			<template-mask ref="maskOverlay"
							:mask-url="maskUrl"
							:document-name="documentName"
							v-show="showTemplateMask" />
			<video ref="video" id="videoElement" autoplay playsinline muted v-show="!loading" @pause="videoPaused"></video>
		</div>
		<div class="landscape-frame__right">
			<base-button modifiers="accent circle big-circle"
						class="landscape-frame__button"
						v-show="enableShutterButton"
						@click="takePhoto">
				<font-awesome-icon :icon="['fas', 'camera']" />
			</base-button>
			<input ref="fileUpload" id="fileUpload" type="file" accept="image/*" @change="uploadPhoto" hidden />
			<base-button modifiers="accent circle small-circle"
						class="landscape-frame__button landscape-frame__button--upload"
						v-show="showUploadButton && showCameraButtons"
						@click="chooseFiles">
				<font-awesome-icon :icon="['fas', 'file-upload']" />
			</base-button>
		</div>
		<canvas ref="canvas"></canvas>
	</div>
</template>

<style lang="scss" scoped>
	.landscape-frame {
		display: flex;
		width: 100%;
		height: 100vh;
		background-color: black;
		pointer-events: none;
		&__left {
			display: flex;
			justify-content: center;
			width: 10%;
		}
		&__middle {
			display: flex;
			width: 70%;
			position: relative;
			video {
				width: 100%;
			}
		}
		&__right {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 20%;
			padding: 1rem;
		}
		&__button {
			font-size: 3rem;
			width: 5.5rem;
			height: 5.5rem;
			padding: 1rem;
			pointer-events: visible;
			@media (max-width: 840px) {
				width: auto;
				height: auto;
				font-size: 2.5rem;
			}
			&--close {
				height: 3.5rem;
				width: 3.5rem;
				min-height: 0;
				font-size: 2.5rem;
				padding: 0;
				margin: 0;
				position: absolute;
				top: 2rem;
				@media (max-width: 840px) {
					height: 2.8rem;
					width: 2.8rem;
					font-size: 1.8rem;
					top: 1.5rem;
				}
			}
			&--upload {
				height: 4rem;
				width: 4rem;
				min-height: 0;
				font-size: 2rem;
				padding: 0;
				margin: 0;
				position: absolute;
				bottom: 2rem;
				@media (max-width: 840px) {
					bottom: 1.5rem;
					height: 3rem;
					width: 3rem;
					font-size: 1.8rem;
				}
			}
		}
		canvas {
			display: none;
		}
	}
</style>
